<template>
  <div>
    <Modal class="z-modal" @close-modal="$emit('close-modal')">
      <div v-if="isImpersonating" class="p-24">
        <AlertBar v-if="responseError"
                  class="mt-24"
                  :alert-type="'error'"
                  :text="responseError" />
        <div class="mt-24">
          <Button :is-block="true"
                  :text="$lang('loginResources', 'stopImpersonation')"
                  :size="Sizes.lg"
                  :theme="Themes.dark"
                  :loading="logOutLoading"
                  @click="onLogOut" />
        </div>
      </div>
      <div v-else class="p-24">
        <div class="base-medium-text mb-16">
          <p>Enter the email of the person you want to impersonate below</p>
        </div>
        <div class="border-grey600 rounded-t rounded-b last:rounded-b last:border-b">
          <div class="form-row first:rounded-t last:rounded-b last:border-b">
            <div class="flex-1">
              <TextInput :id="'checkoutSignInEmail'"
                         :placeholder="$lang('checkoutResources', 'formEmail')"
                         :type="'email'"
                         :form-input="true"
                         :error="emailError"
                         @submitInput="emailInput" />
            </div>
          </div>
        </div>
        <AlertBar v-if="responseError"
                  class="mt-24"
                  :alert-type="'error'"
                  :text="responseError" />
        <div class="mt-24">
          <Button :is-block="true"
                  :text="$lang('checkoutResources', 'signInButton')"
                  :size="Sizes.lg"
                  :theme="Themes.dark"
                  :loading="logInLoading"
                  @click="onLogIn" />
        </div>
      </div>
    </Modal>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useUserStore } from '~/store/user';
import { Sizes, Themes } from '../constants/button-layouts';
import TextInput from '../form-elements/TextInput.vue';
import { ref } from 'vue';
import * as Sentry from '@sentry/vue'

const userStore = useUserStore();
const globalContentStore = useGlobalContentStore();
const config = useGlobalContentStore().config;
const { $event, $lang } = useNuxtApp();
const { lastError, apiPost, apiDelete } = useApiFetch();

const email = ref('');
const emailError = ref('');
const responseError = ref('');
const logInLoading = ref(false);
const logOutLoading = ref(false);

const isImpersonating = computed(() => {
  return config.isImpersonating;
});

const emailInput = (input: string) => {
  email.value = input;
  responseError.value = '';

  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    emailError.value = '';
  } else if (!input) {
    emailError.value = '';
  } else {
    emailError.value = $lang('validationErrorResources', 'invalidEmailShort');
  }
};

const router = useRouter();
const onLogIn = async () => {
  try {
    logInLoading.value = true;
    await apiPost(`user/impersonate/start/`, { 'email': email.value });

    const errorMessage = lastError.value;

    if (errorMessage) {
      responseError.value = errorMessage;
    } else {
      router.go(0);
    }
  } catch (e) {
    // $sentry.captureException(e);
    const message = e.response?.data?.message || '';
    $event('trigger-error-bar', {
      message
    });
  } finally {
    logInLoading.value = false;
  }
};

const onLogOut = async () => {
  try {
    logOutLoading.value = true;
    const res = await apiDelete(`user/impersonate/end`);

    const errorMessage = res ? res.message : '';

    if (errorMessage) {
      responseError.value = errorMessage;
    } else {
      router.go(0);
    }
  } catch (e) {
    // $sentry.captureException(e);
    const message = e.response?.data?.message || '';
    $event('trigger-error-bar', {
        message
      });
  } finally {
    logOutLoading.value = false;
  }
};
</script>
<style scoped>
  .form-row {
    @apply flex border-t border-l border-r;
  }
</style>
